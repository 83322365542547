import { ErrorMessage, Field, useFormikContext } from "formik";
import { InputText, InputTitle } from "./styled-step1";

import React from "react";
import Select from "@components/CustomSelect";
import { Values } from "@views/Walkthrough";

const Step1 = () => {
  const formik = useFormikContext<Values>();

  const selectList = [
    { id: 0, label: "", disabled: true },
    { id: 1, label: "Franchisee" },
    { id: 2, label: "Vendor" },
    { id: 3, label: "General Contractor" },
    { id: 4, label: "Consultant" },
    { id: 5, label: "Other" },
  ];

  return (
    <div className="steps step-1">
      <InputTitle className="o-ft-sm-400">I am a</InputTitle>

      <div className="mb-4">
        <Select
          items={selectList}
          name="iamfield"
          placeholder="Select your role"
        />
      </div>

      {formik.values.iamfield === "Other" && (
        <>
          <InputTitle $error={!!formik.errors.rolefield}>
            Please enter your role*
          </InputTitle>
          <InputText $error={!!formik.errors.rolefield}>
            <Field
              type="text"
              name="rolefield"
              placeholder="Please enter your role"
              value={formik.values.rolefield}
            />
            <ErrorMessage
              name="rolefield"
              render={(error) => (
                <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
              )}
            />
          </InputText>
        </>
      )}
    </div>
  );
};

export default Step1;
