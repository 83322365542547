/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage, Field, useFormikContext } from "formik";
import { InputSubtitle, InputText } from "./styled-step1";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { EToastTypes } from "@models/toast/Toast.type";
import { Spinner } from "react-activity";
import { Values } from "@views/Walkthrough";
import { addToast } from "@redux/toasts/slices/toastsSlice";
import debounce from "lodash/debounce";
import { useDispatch } from "react-redux";
import { useGetCompanies } from "@redux/onboarding/api";

type OnChangeFunction = () => void;

const Step2 = () => {
  const dispatch = useDispatch();
  const { touched, errors, setFieldValue, validateField } =
    useFormikContext<Values>();

  const [search, setSearch] = useState<string | undefined>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: companiesData, isLoading } = useGetCompanies({ search });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef<OnChangeFunction | null>(null);
  const [showAddButton, setShowAddButton] = useState(false);

  const handleSelectCompany = (companyName: string) => {
    setFieldValue("wasCompanySelected", true);
    setFieldValue("companyFullNameField", companyName);
    setTimeout(() => {
      validateField("companyFullNameField");
    }, 0);
    validateField("companyFullNameField");
    setSearchTerm(companyName);

    setDropdownOpen(false);
    setShowAddButton(true);
  };

  const onAddCompany = () => {
    const toast = {
      id: 1,
      text: "Company added successfully!",
      type: EToastTypes.PRIMARY,
      autohide: true,
    };
    dispatch(addToast(toast));
    setSearchTerm(searchTerm);
    setDropdownOpen(false);
    setShowAddButton(false);
    setFieldValue("wasCompanySelected", true);
    setTimeout(() => {
      validateField("companyFullNameField");
    }, 0);
    validateField("companyFullNameField");
  };

  const onChange = useCallback(() => {
    if (searchTerm && searchTerm?.length >= 4) {
      setSearch(searchTerm);
      setFieldValue("wasCompanySelected", false);
      setDropdownOpen(true);
    }
    if (searchTerm && searchTerm?.length >= 2) {
      setShowAddButton(true);
    }
  }, [searchTerm]);

  useEffect(() => {
    ref.current = onChange;
  }, [onChange]);

  const doCallbackWithDebounce = useMemo(() => {
    const callback = () => {
      if (ref.current) {
        ref.current();
      }
    };
    return debounce(callback, 800);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === " ") {
      onAddCompany();
    }
  };

  const renderDropdownContent = () => {
    if (isLoading) {
      return <Spinner color="o-cl-brand-primary" size={20} />;
    }

    if (companiesData.length === 0) {
      return (
        <li className="px-2 py-1 o-ft-sm-400 o-cl-grey-100">No results</li>
      );
    }

    return companiesData.map((company: any) => (
      <div
        className="px-2 py-1 o-cursor-pointer o-ft-sm-400 o-cl-grey-100"
        key={company.id}
        onClick={() => handleSelectCompany(company.name)}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            handleSelectCompany(company.name);
          }
        }}
      >
        {company.name}
      </div>
    ));
  };

  return (
    <div className="steps step-2">
      <h6 className="o-ft-base-400">Personal Information</h6>
      <br />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="mb-4">
            <InputSubtitle
              $error={!!errors.firstNameField && !!touched.firstNameField}
            >
              First Name
            </InputSubtitle>
            <InputText
              $error={!!errors.firstNameField && !!touched.firstNameField}
            >
              <Field
                type="text"
                name="firstNameField"
                placeholder="Enter First Name"
              />
            </InputText>
            <ErrorMessage
              name="firstNameField"
              render={(error) => (
                <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
              )}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="mb-4">
            <InputSubtitle
              $error={!!errors.lastNameField && !!touched.lastNameField}
            >
              Last Name
            </InputSubtitle>
            <InputText
              $error={!!errors.lastNameField && !!touched.lastNameField}
            >
              <Field
                type="text"
                name="lastNameField"
                placeholder="Enter Last Name"
              />
            </InputText>
            <ErrorMessage
              name="lastNameField"
              render={(error) => (
                <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
              )}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="mb-4 position-relative">
            <InputSubtitle
              $error={
                !!errors.companyFullNameField && !!touched.companyFullNameField
              }
            >
              Company Legal Full Name
            </InputSubtitle>
            <InputText
              $error={
                !!errors.companyFullNameField && !!touched.companyFullNameField
              }
            >
              <div className="position-relative">
                <Field
                  type="text"
                  name="companyFullNameField"
                  placeholder="Enter Company Full Name"
                  // value={searchTerm}
                  onChange={(e: any) => {
                    setFieldValue("companyFullNameField", e.target.value);
                    doCallbackWithDebounce();
                    setSearchTerm(e.target.value);
                  }}
                />

                {showAddButton && searchTerm && (
                  <div
                    className="step-2__add-button position-absolute"
                    role="button"
                    tabIndex={0}
                    onClick={onAddCompany}
                    onKeyDown={handleKeyDown}
                  >
                    <span className="o-ft-xs-700 o-cl-inspire-red">+ Add</span>
                  </div>
                )}
              </div>

              {dropdownOpen && searchTerm.length > 3 && (
                <ul className={`step-2__dropdown-list `}>
                  {renderDropdownContent()}
                </ul>
              )}
            </InputText>
            <ErrorMessage
              name="companyFullNameField"
              render={(error) => (
                <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
              )}
            />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="mb-4">
            <InputSubtitle
              $error={!!errors.jobTitleField && !!touched.jobTitleField}
            >
              Job Title
            </InputSubtitle>
            <InputText
              $error={!!errors.jobTitleField && !!touched.jobTitleField}
            >
              <Field
                type="text"
                name="jobTitleField"
                placeholder="Enter Job Title"
              />
            </InputText>
            <ErrorMessage
              name="jobTitleField"
              render={(error) => (
                <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
              )}
            />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="mb-4">
            <InputSubtitle
              $error={!!errors.emailAddressField && !!touched.emailAddressField}
            >
              Email Address
            </InputSubtitle>
            <InputText
              $error={!!errors.emailAddressField && !!touched.emailAddressField}
            >
              <Field
                type="text"
                name="emailAddressField"
                placeholder="Enter Address"
              />
            </InputText>
            <ErrorMessage
              name="emailAddressField"
              render={(error) => (
                <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
