/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import {
  CtaButtonNext,
  CtaButtonReturn,
  StaticBottomButtons,
} from "@views/Walkthrough-styled";
import { Form, Formik, FormikConfig, FormikValues } from "formik";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {}

export const FormikStep: React.FC<PropsWithChildren<FormikStepProps>> = ({
  children,
}) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const FormikStepper: React.FC<
  PropsWithChildren<
    FormikConfig<FormikValues> & {
      onStepChange?: (step: number) => void;
      setValueFunction?: any;
    }
  >
> = ({ children, onStepChange, setValueFunction, ...props }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const childrenArray = React.Children.toArray(
    children,
  ) as React.ReactElement<FormikStepProps>[];
  const currentChild = childrenArray[step];
  const isLastStep = useMemo(
    () => step === childrenArray.length - 1,
    [step, childrenArray],
  );

  useEffect(() => {
    onStepChange?.(step);
  }, [step, onStepChange]);

  const formState = {
    address_one: false,
    address_two: false,
    brands: false,
    city_field: false,
    companyFullNameField: false,
    country: false,
    emailAddressField: false,
    firstNameField: false,
    iamfield: false,
    jobTitleField: false,
    lastNameField: false,
    rolefield: false,
    state_field: false,
    zipcode_field: false,
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep) {
          await props.onSubmit(values, helpers);
        } else {
          setStep((s) => s + 1);
          helpers.resetForm({ touched: formState, values });
        }
      }}
    >
      {({ isValid, isSubmitting, dirty, setErrors }) => {
        return (
          <Form
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {currentChild}
            <StaticBottomButtons>
              <div className="col-12 col-md-8 offset-md-2 px-3 px-md-4 d-flex">
                <div className="col-4">
                  <CtaButtonReturn onClick={() => navigate("/login")}>
                    Back to Login
                  </CtaButtonReturn>
                </div>
                <div className="col-8 d-flex justify-content-end">
                  {step > 0 && (
                    <CtaButtonNext
                      className="mx-3"
                      aria-disabled={isSubmitting}
                    >
                      <input
                        type="button"
                        value="Back"
                        disabled={isSubmitting}
                        onClick={() => {
                          setStep((s) => s - 1);
                          setErrors({});
                        }}
                      />
                    </CtaButtonNext>
                  )}
                  <CtaButtonNext
                    className="mx-2"
                    aria-disabled={!(isValid || dirty) || isSubmitting}
                  >
                    <input
                      type="submit"
                      value={isLastStep ? "Submit" : "Next"}
                      disabled={!(isValid || dirty) || isSubmitting}
                    />
                  </CtaButtonNext>
                </div>
              </div>
            </StaticBottomButtons>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormikStepper;
