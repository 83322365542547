/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, createContext } from "react";

// Create a context for the user data
const UserContext = createContext({});

const RequestAccessProvider = ({
  children,
  value,
}: {
  children: ReactElement;
  value: any;
}) => {
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default RequestAccessProvider;
