import { ErrorMessage, Field, useFormikContext } from "formik";
import { InputSubtitle, InputText } from "./styled-step1";
import React, { ChangeEvent } from "react";

import { Values } from "@views/Walkthrough";

const Step5 = () => {
  const formik = useFormikContext<Values>();

  const handleInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > 500) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.slice(0, 500);
    }
    formik.setFieldValue("reasonAccess", event.target.value);
  };

  return (
    <div className="steps step-5">
      <h6 className="o-ft-base-400">Reason for Access</h6>
      <p className="o-ft-sm-400 o-cl-grey-200 mb-2">
        Please provide the reason for needing access to this platform. The more
        details you provide, the quicker we can review your request.
      </p>
      <div className="row">
        <div className="col-12">
          <InputText
            $error={
              formik.values.reasonAccess.length === 500 ||
              (!!formik.errors.reasonAccess && !!formik.touched.reasonAccess)
            }
          >
            <div className="position-relative">
              <Field
                as="textarea"
                type="text"
                name="reasonAccess"
                placeholder="Enter a reason"
                rows="6"
                onInput={handleInput}
              />
              <div
                className={`c-steps-textarea-container ${formik.values.reasonAccess.length === 500 && "c-steps-textarea-limit"}`}
              >
                <p>{`${formik.values.reasonAccess.length}/500`}</p>
              </div>
            </div>
            <ErrorMessage
              name="reasonAccess"
              render={(error) => (
                <div className="o-ft-xs-400 o-cl-red my-1">{error}</div>
              )}
            />
          </InputText>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="mb-4 c-steps-group-input">
              <h6 className="o-ft-base-400 mt-3">Referral</h6>
              <p className="o-ft-sm-400 o-cl-grey-200">
                Please provide the full name of the main point of contact who
                has referred you to this platform.
              </p>
              <div>
                <InputSubtitle
                  $error={
                    !!formik.errors.referralName &&
                    !!formik.touched.referralName
                  }
                  $marginLeft
                >
                  Referral Full Name
                </InputSubtitle>
                <InputText
                  $error={
                    !!formik.errors.referralName &&
                    !!formik.touched.referralName
                  }
                >
                  <Field
                    type="text"
                    name="referralName"
                    placeholder="Enter referral name"
                  />
                </InputText>
              </div>

              <ErrorMessage
                name="referralName"
                render={(error) => (
                  <div className="o-ft-xs-400 o-cl-red my-1">{error}</div>
                )}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-4 c-steps-group-input">
              <h6 className="o-ft-base-400 mt-3">Referral Email Address</h6>
              <p className="o-ft-sm-400 o-cl-grey-200">
                Please provide the full and valid email address of the Referral
                point of contact.
              </p>
              <div
                className={`${
                  !!formik.errors.referralEmail &&
                  !!formik.touched.referralEmail &&
                  !!formik.errors.referralName &&
                  !!formik.touched.referralName
                    ? "c-steps-input-second"
                    : !!formik.errors.referralEmail &&
                      !!formik.touched.referralEmail &&
                      "c-steps-input-second-big"
                }`}
              >
                <InputSubtitle
                  $error={
                    !!formik.errors.referralEmail &&
                    !!formik.touched.referralEmail
                  }
                  $marginLeft
                >
                  Referral email address
                </InputSubtitle>
                <InputText
                  $error={
                    !!formik.errors.referralEmail &&
                    !!formik.touched.referralEmail
                  }
                >
                  <Field
                    type="text"
                    name="referralEmail"
                    placeholder="Enter referral email address"
                  />
                </InputText>
              </div>
              <ErrorMessage
                name="referralEmail"
                render={(error) => (
                  <div className="o-ft-xs-400 o-cl-red my-1">{error}</div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step5;
