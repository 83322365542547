/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, useFormikContext } from "formik";

import MaterialIcon from "@components/MaterialIcon";
import React from "react";
import { Values } from "@views/Walkthrough";

const Step4: React.FC<{ columns: any[] }> = ({ columns }) => {
  const formik = useFormikContext<Values>();

  const handleChange = (value: { name: string; code: string }) => {
    const currentBrands = formik.values.brands;
    const result = currentBrands.includes(value)
      ? currentBrands.filter(
          (brand: { name: string; code: string }) => brand.code !== value.code,
        )
      : currentBrands.concat(value);
    formik.setFieldValue("brands", result);
  };

  return (
    <div className="steps step-4">
      <p className="o-ft-base-400">Brand/s that you will need access to:</p>
      <div className="row">
        <div className="col-12 col-md-6">
          {columns[0].map((brand: any) => (
            <div
              key={brand.code}
              className="mb-4 o-cursor-pointer o-user-select-none d-flex align-items-center gap-1"
              onClick={() => handleChange(brand)}
              aria-hidden
            >
              <Field type="checkbox" name="brands" value={brand} hidden />
              <MaterialIcon
                icon={
                  formik.values.brands.includes(brand)
                    ? "check_box"
                    : "check_box_outline_blank"
                }
                size={24}
                color={formik.errors.brands ? "o-cl-red" : ""}
              />
              <span className="o-ft-sm-400 o-cl-grey-100 o-user-select-none">
                {brand.name}
              </span>
            </div>
          ))}
        </div>

        <div className="col-12 col-md-6">
          {columns[1].map((brand: any) => (
            <div
              key={brand.code}
              className="mb-4 o-cursor-pointer o-user-select-none d-flex align-items-center gap-1"
              onClick={() => handleChange(brand)}
              aria-hidden
            >
              <Field type="checkbox" name="brands" value={brand} hidden />
              <MaterialIcon
                icon={
                  formik.values.brands.includes(brand)
                    ? "check_box"
                    : "check_box_outline_blank"
                }
                size={24}
                color={formik.errors.brands ? "o-cl-red" : ""}
              />
              <span className="o-ft-sm-400 o-user-select-none">
                {brand.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Step4;
