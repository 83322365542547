/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage, useFormikContext } from "formik";
import { InputSubtitle, InputText } from "./styled-step1";
import React, { useEffect, useState } from "react";

import Select from "@components/CustomSelect";
import { Values } from "@views/Walkthrough";
import sortBy from "lodash/sortBy";

const Step3 = () => {
  const formik = useFormikContext<Values>();

  const [countries, setCountries] = useState<{ id: number; label: string }[]>(
    [],
  );

  useEffect(() => {
    const apiUrl = "https://restcountries.com/v3.1/all";

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const formatedData = data.map((country: any, i: number) => ({
          id: i,
          label: country.name.common,
        }));
        const sortedData = sortBy(formatedData, ["label"]);

        setCountries(sortedData);
      })
      .catch((error) =>
        console.error("Error al obtener la lista de países:", error),
      );
  }, []);

  return (
    <div className="steps step-3">
      <h6 className="o-cl-grey-100">Company Full Address</h6>
      <p className="o-ft-sm-400 o-cl-grey-200">
        This information is needed for NDA-signing purposes before accessing the
        application. It will no be shown publicly or displayed anywhere in the
        platform.
      </p>
      <div className="row">
        <div className="col-12 col-md-6 mb-4">
          <InputSubtitle
            $error={!!formik.errors.address_one && !!formik.touched.address_one}
          >
            Address 1
          </InputSubtitle>
          <InputText
            $error={!!formik.errors.address_one && !!formik.touched.address_one}
          >
            <input
              type="text"
              name="address_one"
              placeholder="Enter Address"
              onChange={formik.handleChange}
              value={formik.values.address_one}
            />
          </InputText>
          <ErrorMessage
            name="address_one"
            render={(error) => (
              <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
            )}
          />
        </div>

        <div className="col-12 col-md-6 mb-4">
          <InputSubtitle
            $error={!!formik.errors.address_two && !!formik.touched.address_two}
          >
            Address 2 (Optional)
          </InputSubtitle>
          <InputText
            $error={!!formik.errors.address_two && !!formik.touched.address_two}
          >
            <input
              type="text"
              name="address_two"
              placeholder="Enter Address"
              onChange={formik.handleChange}
              value={formik.values.address_two}
            />
          </InputText>
          <ErrorMessage
            name="address_two"
            render={(error) => (
              <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
            )}
          />
        </div>

        <div className="col-12 col-md-6 mb-4">
          <InputSubtitle
            $error={!!formik.errors.city_field && !!formik.touched.city_field}
          >
            City
          </InputSubtitle>
          <InputText
            $error={!!formik.errors.city_field && !!formik.touched.city_field}
          >
            <input
              type="text"
              name="city_field"
              placeholder="Enter City"
              onChange={formik.handleChange}
              value={formik.values.city_field}
            />
          </InputText>
          <ErrorMessage
            name="city_field"
            render={(error) => (
              <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
            )}
          />
        </div>

        <div className="col-12 col-md-6 mb-4">
          <InputSubtitle
            $error={!!formik.errors.state_field && !!formik.touched.state_field}
          >
            State
          </InputSubtitle>
          <InputText
            $error={!!formik.errors.state_field && !!formik.touched.state_field}
          >
            <input
              type="text"
              name="state_field"
              placeholder="Enter State"
              onChange={formik.handleChange}
              value={formik.values.state_field}
            />
          </InputText>
          <ErrorMessage
            name="state_field"
            render={(error) => (
              <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
            )}
          />
        </div>

        <div className="col-12 col-md-6 mb-4">
          <InputSubtitle
            $error={
              !!formik.errors.zipcode_field && !!formik.touched.zipcode_field
            }
          >
            Zip Code
          </InputSubtitle>
          <InputText
            $error={
              !!formik.errors.zipcode_field && !!formik.touched.zipcode_field
            }
          >
            <input
              type="text"
              name="zipcode_field"
              placeholder="Enter Zip Code"
              onChange={formik.handleChange}
              value={formik.values.zipcode_field}
            />
          </InputText>
          <ErrorMessage
            name="zipcode_field"
            render={(error) => (
              <div className="o-ft-xs-400 o-cl-red my-1 mx-2">{error}</div>
            )}
          />
        </div>

        <div className="col-12 col-md-6 mb-4">
          <InputSubtitle
            $error={!!formik.errors.country && !!formik.touched.country}
          >
            Country
          </InputSubtitle>
          <Select height="5rem" items={countries} name="country" />
        </div>
      </div>
    </div>
  );
};

export default Step3;
