export default {
  brandColorPrimary: '#EDB900',
  brandColorSecondary: '#FFC600',
  brandColorTertiary: '#FFC600',
  brandColorLight: '#FFC600',


  brandColor: '#EDB900',
  purpleLight: '#FFC600',
}
